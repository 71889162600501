<template>
  <div>
    <div class='md-layout report-types'>
      <div class='md-layout-item'>
        <md-field>
          <label for='reportType'>{{ $t('report.report_types') }}</label>
          <md-select
              id='reportType'
              v-model='selectedReportType'
              :disabled='!isThereReportTypes'
              name='reportType'
              @md-selected='onReportTypesChanged'
          >
            <md-option
                v-for='report of reportTypes'
                :key='report.id'
                :value='report.id'
            >
              {{ $t(report.name) }}
            </md-option>
          </md-select>
        </md-field>
      </div>
    </div>
    <ReportTable
        v-if='isShowReportTable'
        :isReportDataLoading='isReportDataLoading'
        :reportName='selectedReportName'
    ></ReportTable>
  </div>
</template>
<script>
import {ReportTable} from '@/components/viewReport'

export default {
  name: 'Reports',
  components: {ReportTable},

  data() {
    return {
      selectedReportType: 'none',
      reportTypes: [],
      isReportDataLoading: true

    }
  },
  props: {
    report_type: {
      type: String,
      required: true
    },
    domain: {
      type: String,
      required: true
    }
  },
  watch: {
    '$route'(to, from) {
      const {domain: toDomain, report_type: toRepType} = to.params
      const {domain: fromDomain, report_type: fromRepType} = from.params
      if (toDomain !== fromDomain) {
        this.domain = toDomain
        this.onReportTypesChanged('list')
        this.refreshReportsList()
      } else if (fromRepType !== toRepType) {
        this.refreshPage()
      }
    }
  },
  created() {
    this.refreshReportsList()
    if (this.report_type !== 'list') {
      this.selectedReportType = this.report_type
      this.refreshPage()
    }
  },
  methods: {
    async refreshReportsList() {
      this.selectedReportType = 'list'
      this.domain = this.$route.params.domain
      const list = await this.$store.dispatch('LOAD_REPORTS_LIST', this.domain)
      this.reportTypes = [...list]
    },

    async refreshPage() {
      this.isReportDataLoading = true
      try {
        await this.$store.dispatch('LOAD_REPORT_DATA', {type: this.report_type, domain: this.domain})
      } finally {
        this.isReportDataLoading = false
      }
    },
    async onReportTypesChanged(type) {
      this.$router.replace({name: 'reports', params: {domain: this.domain, report_type: type}})
    }
  },
  computed: {
    isThereReportTypes() {
      return this.reportTypes.length > 0
    },
    isShowReportTable() {
      return this.report_type !== 'list'
    },
    selectedReportName() {
      const report = this.reportTypes.find(
          (type) => type.key === this.selectedReportType
      )
      return report ? report.value : ''
    }
  }
}
</script>
<style lang='scss'>
.report-types {
  max-width: 500px;
}

</style>
